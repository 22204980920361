import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { lazy, Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import $ from "jquery";
// import SurveySettings from "./components/admin/createSurvey/SurveySettings/SurveySettings";

// admin side
const Registration = lazy(() => import("./components/admin/registration/Registration"));
const Login = lazy(() => import("./components/admin/login/Login"));
const ForgotPassword = lazy(() => import("./components/admin/forgotPassword/ForgotPassword"));
const ForgotEmail = lazy(() => import("./components/admin/forgotPassword/ForgotEmail"));
const ResetPassword = lazy(() => import("./components/admin/forgotPassword/ResetPassword"));
const Dashboard = lazy(() => import("./components/admin/dashboard/Dashboard"));
const SurveyList = lazy(() => import("./components/admin/surveyList/SurveyList"));
const CreateSurvey = lazy(() => import("./components/admin/createSurvey/AddSurvey/CreateSurvey"));
const AddQuestions = lazy(() => import("./components/admin/createSurvey/AddQuestions/AddQuestions"));
const SurveySettings = lazy(() => import("./components/admin/createSurvey/SurveySettings/SurveySettings"));
const Settings = lazy(() => import("./components/admin/profileSettings/Settings"));
const EditOrganization = lazy(() => import("./components/admin/profileSettings/EditOrganization"));
const ChangeEmailOtp = lazy(() => import("./components/admin/profileSettings/ChangeEmailOtp"));

// common
const PageNotFound = lazy(() => import("./components/common/PageNotFound"));
const HomePage = lazy(() => import("./components/common/home/HomePage"));

// user side
const Introduction = lazy(() => import("./components/user/introduction/Introduction"));
const Survey = lazy(() => import("./components/user/survey/Survey"));
const ThankYou = lazy(() => import("./components/user/thankYou/ThankYou"));
const MySurvey = lazy(() => import("./components/user/mysurvey/MySurvey"));
const Form = lazy(() => import("./components/common/requestDemo/Form"));


function App() {
  const auth = () => {
    if (sessionStorage.getItem("session")) {
      return true;
    } else if (localStorage.getItem("session")) {
      var sessionDate = JSON.parse(localStorage.getItem("session")).date;
      var diff = moment().startOf("day").diff(moment(sessionDate).startOf("day"), "days");
      if (diff >= 0 && diff < 1) {
        return true;
      } else {
        // sessionStorage.clear();
        localStorage.clear();
        return false;
      }
    } else {
      sessionStorage.clear();
      // localStorage.clear();
      return false;
    }
  };

  const body = document.querySelector("body");
  body?.style.setProperty("--screen-height", $(window).height() + "px");

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $(".common_head").addClass("fixed-me");
      $(".black_logo").css("display", "block");
      $("#nav-logo-id").css("display", "none");
    } else {
      $(".common_head").removeClass("fixed-me");
      $("#nav-logo-id").css("display", "block");
      $(".black_logo").css("display", "none");
    }
  });

  const isAuthenticated = auth();

  return (
    <div className="App">
      <Suspense
        fallback={
          <center style={{ paddingTop: "40vh" }}>
            <Spinner animation="border" as="span" variant="info" style={{ height: 100, width: 100 }} />
            <h4 className="pt-5">Loading...</h4>
          </center>
        }>
        <Router>
          <Switch>
            {/* user */}
            <Route exact path="/Introduction/:surveyId">
              <Introduction />
            </Route>
            <Route exact path="/MySurvey" component={MySurvey} />
            <Route exact path="/FeedbackForm" component={Form} />
            <Route exact path="/Survey/:surveyId/:userToken">
              <Survey />
            </Route>
            <Route exact path="/ThankYou/:surveyId">
              <ThankYou />
            </Route>

            {/* common */}
            <Route exact path="/" component={HomePage} />
            {/* <Route exact path="/" render={() => {
              if (!isAuthenticated) { 
                (<HomePage />)
              } else {
                (<Dashboard />)
              }
            }} /> */}

            <Route exact path="/Registration" component={Registration} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/ForgotEmail" component={ForgotEmail} />
            <Route exact path="/ResetPassword/:userId" component={ResetPassword} />
            <Route exact path="/PageNotFound" component={PageNotFound} />

            {/* admin */}
            <ProtectedRoute exact path="/Dashboard" component={Dashboard} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/SurveyList" component={SurveyList} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/CreateSurvey" component={CreateSurvey} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/CreateSurvey/:surveyId" component={CreateSurvey} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/AddQuestions/:surveyId/:survey_name" component={AddQuestions} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/AddQuestions/:surveyId/:survey_name/:page_id" component={AddQuestions} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/SurveySettings/:surveyId" component={SurveySettings} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/SurveySettings/:surveyId/:settings" component={SurveySettings} isAuth={isAuthenticated} />

            <ProtectedRoute exact path="/Settings" component={Settings} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/EditOrganization/:orgId" component={EditOrganization} isAuth={isAuthenticated} />
            <ProtectedRoute exact path="/VerifyEmail/:token/:userId" component={ChangeEmailOtp} isAuth={isAuthenticated} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
