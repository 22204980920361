import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) => {
  useEffect(() => {
    // Update the document title based on page
    const changeTitle = () => {
      let title = window.location.pathname;
      title = title.substring(title.indexOf("/") + 1);
      if (title.includes("/", 0)) title = title.substring(title.lastIndexOf("/") - 1, 0);
      if (title.includes("/", 0)) title = title.substring(title.lastIndexOf("/") - 1, 0);
      document.title = title + " - Openeyes Surveys";
    };

    changeTitle();
  });
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return <Component />;
        } else {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
